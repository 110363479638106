import React, {useContext, createContext, useState, useEffect} from "react";

import { getAllHotels } from "../api/hotels";

const HotelContext = createContext();

const HotelsProvider = ({children}) => {
    const [hotels, setHotels] = useState([]);
    const [hotelsLoading, setHotelsLoading] = useState(true);

    // getting all hotels
    useEffect(() => {
        getAllHotels()
            .then(({data}) => {
                setHotels(data);
            })
            .catch((err) => {
                console.log("error getting hotels", err);
            })
            .finally(() => {
                setHotelsLoading(false);
            })
    }, []);

    return (
        <HotelContext.Provider value={{hotels, hotelsLoading}}>
            {children}
        </HotelContext.Provider>
    )
}

export const useHotels = () => {
    if(!useContext(HotelContext)) { 
        throw new Error("useHotels must be used within an HotelsProvider");
    }
    return useContext(HotelContext);
}

export default HotelsProvider