import React, {useContext, createContext, useState, useEffect} from "react";

import { getAllDestinations, getPopularDestinations } from "../api/destinations";

const DestinationContext = createContext();

const DestinationsProvider = ({children}) => {
    const [destinations, setDestinations] = useState([]);
    const [destinationsLoading, setDestinationsLoading] = useState(true);
    const [popularDestinations, setPopularDestinations] = useState([]);

    // getting all destinations
    useEffect(() => {
        getAllDestinations()
            .then(({data}) => {
                setDestinations(data?.results);
            }) 
            .catch((err) => {
                console.log("error getting destinations", err);
            })
            .finally(() => {
                setDestinationsLoading(false);
            },
        )

        getPopularDestinations()
            .then(({data}) => {
                setPopularDestinations(data);
            }) 
            .catch((err) => {
                console.log("error getting popular destinations", err);
            })
    }, []);
    
    return (
        <DestinationContext.Provider value={{ 
            destinations, 
            destinationsLoading, 
            popularDestinations,
        }}>
            {children}  
        </DestinationContext.Provider>
    )
}

export const useDestinations = ()=> {
    if (!useDestinations) {
        throw new Error("useDestinations must be used within an DestinationProvider");
    }
    return useContext(DestinationContext);
}

export default DestinationsProvider