import React, {useContext, createContext, useState, useEffect} from "react";

import { getAllCategories } from "../api/categories";

const CategoryContext = createContext();

const CategoriesProvider = ({children}) => {
    const [categories, setCategories] = useState([]);
    const [categoriesLoading, setCategoriesLoading] = useState(true);

    // getting all categories
    useEffect(() => {
        getAllCategories()
            .then(({data}) => {
                setCategories(data);
            })
            .catch((err) => {
                console.log("error getting categories", err);
            })
            .finally(() => {
                setCategoriesLoading(false);
            })
    }, []);

    return (
        <CategoryContext.Provider value={{categories, categoriesLoading}}>
            {children}
        </CategoryContext.Provider>
    )
}

export const useCategories = () => {
    if (!useCategories) {
        throw new Error("useCategories must be used within a CategoriesProvider");
    }
    return useContext(CategoryContext);
}

export default CategoriesProvider