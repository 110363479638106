import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production' 
    ? 'https://kofitour.pythonanywhere.com/api-v1/' 
    : 'https://kofitour.pythonanywhere.com/api-v1/';

const client = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 10000,
});

// error handling interceptors
client.interceptors.response.use(
    response => response,
    error => {
       
        console.error('API Error:', error);
        return Promise.reject(error);
    }
);


export default client