import authClient from "./authClient";

const loginEndpoint = "/authenticate/"
const registerEndpoint = "/auth/users/"

export const login = ({email, password}) => authClient.post(loginEndpoint, {
    email,
    password
});

export const signup = ({fullName, email, phone, password}) => authClient.post(registerEndpoint, {
    fullname: fullName,
    email,
    telephone: phone,
    password
});

export const getUser = (id) => authClient.get(`${registerEndpoint}/${id}/`);