import client from "./client";

const endpoint = "/destinations/";

export const getAllDestinations = () => client.get(endpoint);

export const getDestinationsByCategory = (category) => client.get(`${endpoint}category/${category}`);

export const getDestinationsBySearchTerm = (searchTerm) => client.get(`${endpoint}/?search=${searchTerm}`);

export const getDestinationBySlug   = (slug) => client.get(`${endpoint}/${slug}`);

export const getPopularDestinations = () => client.get(`/popular-destinations/`);   