import React, {useContext, createContext, useState, useEffect} from "react";

import { getAllOperators } from "../api/operators";

const OperatorContext = createContext();

const OperatorsProvider = ({children}) => {
    const [operators, setOperators] = useState([]);
    const [operatorsLoading, setOperatorsLoading] = useState(true);
    
    // getting all operators
    useEffect(() => {
        getAllOperators()
            .then(({data}) => {
                setOperators(data);
            })
            .catch((err) => {
                console.log("error getting operators", err);
            })
            .finally(() => {
                setOperatorsLoading(false);
            })
    }, []);
    
    return (
        <OperatorContext.Provider value={{ operators, operatorsLoading }}>
            {children}

        </OperatorContext.Provider>
    )
}

export const useOperators = () => {
    if(!useContext(OperatorContext)) { 
        throw new Error("useOperators must be used within an OperatorsProvider");
    }
    return useContext(OperatorContext);
}

export default OperatorsProvider