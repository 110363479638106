import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from 'react-router-dom'

import './index.css';
import './App.css';

// providers
import AuthProvider from './providers/AuthProvider';
import DestinationsProvider from './providers/DestinationsProvider';
import CategoriesProvider from './providers/CategoriesProvider';
import OperatorsProvider from './providers/OperatorsProvider';
import HotelsProvider from './providers/HotelsProvider';

import ErrorBoundary from './components/ErrorBoundary';

import { FullPageLoader } from './components/uiComponents';

// lazy imports
const LazyHomePage = React.lazy(() => import('./App'));
const LazyAccommodationPage = React.lazy(() => import('./pages/AccommodationPage'));
const LazyTourGuidesPage = React.lazy(() => import('./pages/TourGuidesPage'));
const LazyContactPage = React.lazy(() => import('./pages/ContactPage'));
const LazyAboutPage = React.lazy(() => import('./pages/AboutPage'));
const LazyAuthPage = React.lazy(() => import('./pages/AuthPage'));
const LazyBlogSearchResultsPage = React.lazy(() => import('./pages/BlogSearchResultsPage'));
const LazyHotelsSearchResultsPage = React.lazy(() => import('./pages/HotelsSearchResultsPage'));
const LazyAllBlogsPage = React.lazy(() => import('./pages/AllBlogsPage'));
const LazyAllDestinationsPage = React.lazy(() => import('./pages/AllDestinationsPage'));
const LazyPrivacyPage = React.lazy(() => import('./pages/PrivacyPage'));
const LazyTermsPage = React.lazy(() => import('./pages/TermsPage'));

const LazyBlogDetailsPage = React.lazy(() => import('./pages/details/BlogDetailsPage'));
const LazyDestinationDetailsPage = React.lazy(() => import('./pages/details/DestinationDetailsPage'));
const LazyHotelsDetailsPage = React.lazy(() => import('./pages/details/HotelsDetailsPage'));
const LazyTourGuideDetails = React.lazy(() => import('./pages/details/TourGuideDetails'));

const LazyPage404 = React.lazy(() => import('./pages/Page404'));


const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyHomePage />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: "/accommodation",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyAccommodationPage />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: "/tour-operators",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyTourGuidesPage />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: "/contact",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyContactPage />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: "/about",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyAboutPage />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: "/auth",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyAuthPage />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: "/attractions-search-results/:searchTerm",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyBlogSearchResultsPage />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: "/hotels-search-results/:searchTerm",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyHotelsSearchResultsPage />
        </React.Suspense>
      </ErrorBoundary>
    ),
  }, 
  // changed blog to destinations
  {
    path: "/blogs/:id",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyBlogDetailsPage />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: "/destinations/:slug",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyDestinationDetailsPage />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: "/hotels/:id",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyHotelsDetailsPage />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: "/tour-guides/:slug",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyTourGuideDetails />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: "/all-blogs",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyAllBlogsPage />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: "/all-attractions",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyAllDestinationsPage />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyPrivacyPage />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: "/terms-and-conditions",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyTermsPage />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: "*",
    element: (
      <ErrorBoundary>
        <React.Suspense fallback={<FullPageLoader />}>
          <LazyPage404 />
        </React.Suspense>
      </ErrorBoundary>
    ),
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <HotelsProvider>
        <OperatorsProvider>
          <CategoriesProvider>
            <DestinationsProvider>
              <RouterProvider router={router} />
            </DestinationsProvider>
          </CategoriesProvider>
        </OperatorsProvider>
      </HotelsProvider>
    </AuthProvider>
  </React.StrictMode>
);
