import React from 'react'

const Toast = ({visible, status}) => {
    if(!status) return null

    const { type, title, desc } = status;

    const typeStyles = {
        success: {
          icon: "fas fa-check",
          iconBg: "bg-primary",
          textColor: "text-secondary",
        },
        error: {
          icon: "fas fa-times",
          iconBg: "bg-red-500",
          textColor: "text-red-400",
        },
        info: {
          icon: "fas fa-info-circle",
          iconBg: "bg-blue-500",
          textColor: "text-blue-400",
        },
        warning: {
          icon: "fas fa-exclamation-triangle",
          iconBg: "bg-yellow-500",
          textColor: "text-yellow-400",
        },
      };

      const { icon, iconBg, textColor } = typeStyles[type] || typeStyles.info;

  return (
    <div className={`flex flex-col gap-2 w-72 sm:w-80 text-[10px] sm:text-xs ${!visible && 'hide-toast'} opacity-100 visible translate-y-0 duration-1000 shadow fixed bottom-32 right-4 z-[10]`} role='alert'>
    <div
        className={`cursor-default flex items-center justify-between w-full h-[6.5rem] sm:h-[8rem] rounded-lg ${iconBg} px-[10px]`}
    >
        <div className="flex gap-2 items-center  h-full">
            <div className={`rounded-lg w-12 h-12 flex-shrink-0 flex-center text-xl bg-primary-300 ${textColor}`}>
                <i className={icon}></i>
            </div>
            <div className="flex flex-col gap-1 justify-between">
                <p className="text-secondary bg-[rgba(0,0,0,0.4)] text-center rounded-t-md uppercase absolute top-0 left-0 right-0 py-1">{title}</p>
                <p className="text-secondary text-xs sm:text-sm">{desc}</p>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Toast