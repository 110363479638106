import React, { createContext, useState, useEffect, useCallback } from "react";
import { login, getUser, signup } from "../api/user";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [signupLoading, setSignupLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [signupError, setSignupError] = useState(null);

  // Handle user login
  const handleLogin = async (credentials) => {
    try {
      setLoginLoading(true);
      const response = await login(credentials);
      console.log("Login response:", response.data);
      setUser(response.data); 
      setIsAuthenticated(true);
      setLoginError(null);
      return true;
    } catch (error) {
      setLoginError(error.response?.data?.detail || error.message);
      return false;
    } finally {
      setLoginLoading(false);
    }
  };

  // Handle user registration
  const handleSignup = async (userData) => {
    try {
      setSignupLoading(true);
      const response = await signup(userData);
      console.log("Signup response:", response.data);
      setUser(response.data);
      setIsAuthenticated(true);
      setSignupError(null);
      return true;
    } catch (error) {
      console.log("Signup failed:", error.response?.data || error.message);
      setSignupError(error.response?.data?.email?.[0] || error.message);
      return false;
    } finally {
      setSignupLoading(false);
    }
  };

  // Fetch the current user
  const fetchUser = useCallback(async () => {
    try {
      const response = await getUser();
      setUser(response.data);
    } catch (error) {
      console.error("Failed to fetch user:", error.response?.data || error.message);
      handleLogout();
    }
  }, []);

  // Handle logout
  const handleLogout = () => {
    setUser(null);
    setIsAuthenticated(false);
  };

  // Load user info on initial render
  useEffect(() => {
    if (isAuthenticated && !user) {
      fetchUser();
    }
  }, [isAuthenticated, user, fetchUser]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        handleLogin,
        loginLoading,
        loginError,
        handleSignup,
        signupLoading,
        signupError,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export default AuthProvider;
