import React from 'react'

import './fullPageLoader.css'

const FullPageLoader = () => {
  return (
    <div className="h-screen flex-center bg-primary-100 fixed inset-0" role='status'>
        <div className="spinner" aria-label='loading...'>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
  )
}

export default FullPageLoader