import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production' 
    ? 'https://kofitour.pythonanywhere.com/' 
    : 'https://kofitour.pythonanywhere.com/';

const authClient = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 10000,
});

// error handling interceptors
authClient.interceptors.response.use(
    response => response,
    error => {
       
        console.error('API Error:', error);
        return Promise.reject(error);
    }
);


export default authClient